@import url("https://fonts.googleapis.com/css2?family=Freehand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Habibi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Krona+One&display=swap");

@font-face{
  font-family: 'euclid';
  src: url('./assets/font-a/euclid-R.ttf');
}
@font-face{
  font-family: 'euclidL';
  src: url('./assets/font-a/euclid-L.ttf');
}
@font-face{
  font-family: 'hestina';
  src: url('./assets/font-a/hestina/hestina.otf');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  height: 100%;
}
body {
  margin: 0;
  background-color: #eee; 
  font-family: "euclid, Habibi", "Freehand", "Krona One";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
}

code {
  font-family: "euclid";
}
.font-hestina{
  font-family: "hestina"
}
.font-euclidL{
  font-family: "euclidL"
}
.font-euclid{
  font-family: "euclid"
}
.font-freehand {
  font-family: "Freehand";
}
.font-krona {
  font-family: "Krona One";
}
.font-habibi {
  font-family: "Habibi";
}

.font-system {
  font-family: "system-ui";
}

@media screen and (min-width:768px){
  .MainBox{
    padding: 15px 0px;
  }
}
.invitaionBox{
  max-width: 690px;
  min-height: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 557px 223px rgba(0, 0, 0, 0.01), 0px 314px 188px rgba(0, 0, 0, 0.05), 0px 139px 139px rgba(0, 0, 0, 0.09), 0px 35px 77px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width:767px){
  .invitaionBox{
  width: calc(100% - 0px);
  height: 100%;
  }
}

.notValid{
  min-height: 650px;
}

p{
  margin: 0px !important;
}

a{
  text-decoration: none;
}

.clipy {
  position: absolute;
  top: 50%;
  left: 4%;
  transform: translate(-50%, -50%);
  clip-path: polygon(40% 0, 100% 0%, 60% 100%, 0% 100%);
}
.center {
  left: 50%;
  transform: translate(-50%, -50%);
}

.bg-foot {
  background-image: url("./assets/images/bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom
}

.divider{
 width: 90%;
 margin: 0px auto 50px;
 height: 1px;
 background-color: #4f4f4f;
 opacity: 0.6;
  
}

@media screen and (max-width:767px){
  .divider{
    margin: 0px auto 30px;
  }
}
.footerLogo img{
  height: 20px;
}
.notFound:first-child{
  border-bottom: 1px solid #4f4f4f;
    border-top: 1px solid #4f4f4f;
    padding: 12px 0px;
  }
  .notFound:last-child{
    padding: 12px 0px;
    border-bottom: 1px solid #4f4f4f;

}
.NotFoundBox{
  padding: 0px;
  text-align: center;
  position: absolute;
  top: calc(60% - 185px);
  /* left: 39%; */
  width: 90%;
  left: 5%;
} 

.buttonBox{
  display: flex;
  flex-direction: row;
  justify-content: center;
  
  margin-bottom: 30px !important;
}

.buttonBox button{
  width: 200px;
  height: 44px;
  background-color: #4E5154;
  color:#fff;
  border-radius: 5px;
  justify-content: center;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

}
@media screen and (max-width:768px){
  .buttonBox button{
    width: 150px;
    height: 38px;
  }
}


.buttonBox a:first-child{
  margin-right: 15%;
}
.buttonBox button svg{
  margin-right: 4px;
}

@media screen and (max-width:374px){
  .buttonBox button{
    width: 140px;
    height: 38px;
  }
  .buttonBox a:first-child{
    margin-right: 5%
  }
}
.red{
  color: #9B0038
}
.green{
  color: #009B22
}