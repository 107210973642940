.customHeight{
    /* height: 100dvh; */
}
.border-img{
    /* background-image: url('../assets/images/AnualMajlis24PaternV3.svg'); */
    background-image: url('../assets/images/AnualMajlis24PaternV2.svg');
    /* background-image: url('../assets/images/AnualMajlis24BG.png'); */
    /* background-image: url('../assets/images/AnualMajlis24Pattern.svg'); */
    /* background-color: #bc1818; */
    /* border: 2px solid #ddd; */
    /* border-radius: 4px; */
    /* padding: 5px; */
    width: 100%;
    height: 35px;
    position: absolute;
    background-size: contain;
    background-repeat: repeat-x;
}
.top-img{
    background-position: top;
    top: 0px;
}
.bottom-img{
    background-position: bottom;
    bottom: 0px;
}
@media screen and (max-width: 768px) {
    .border-img{
        background-size: cover;
    }
    
}